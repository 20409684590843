import gql from 'graphql-tag';
import { CustomerWithAddressesQuery as CustomerWithAddressesQueryBase } from '@deity/falcon-shop-data';

export const GET_CUSTOMER_WITH_ADDRESSES = gql`
  query CustomerWithAddresses {
    customer {
      id
      firstname
      lastname
      email
      prefix
      addresses {
        id
        company
        firstname
        lastname
        street
        postcode
        city
        countryId
        defaultBilling
        defaultShipping
        region
        regionId
        telephone
        shipToPackstation
        dhlAccount
      }
      newsletterSubscriber
    }
  }
`;

export class CustomerWithAddressesQuery extends CustomerWithAddressesQueryBase {
  static defaultProps = {
    query: GET_CUSTOMER_WITH_ADDRESSES
  };
}
