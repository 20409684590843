import React from 'react';
import { isValidJson } from '../../helpers/Checkers/Checkers';
import footerSprite from '../../../../../assets/images/sprites/footerSprites.svg';
import styles from './Footer.module.scss';

export const FooterCarriers = ({ carriers }) => {
  const carriersList = [];

  const getHeight = carrierCode => {
    let footerLogoHeight = '';

    switch (carrierCode) {
      case 'dhl':
        footerLogoHeight = '34';
        break;
      case 'dhl_express':
        footerLogoHeight = '48';
        break;
      case 'seven_senders':
        footerLogoHeight = '34';
        break;
      case 'poczta_polska':
        footerLogoHeight = '32';
        break;
      case 'colissimo':
        footerLogoHeight = '40';
        break;
      case 'postAT':
        footerLogoHeight = '64';
        break;
      case 'die_post':
      case 'la_poste':
        footerLogoHeight = '54';
        break;
      default:
        footerLogoHeight = '34';
        break;
    }

    return footerLogoHeight;
  };

  if (carriers && isValidJson(carriers)) {
    Object.values(JSON.parse(carriers)).map((item, index) => {
      return carriersList.push(
        // eslint-disable-next-line react/no-array-index-key
        <div className={styles.CarrierItem} key={`carrier-${index}`}>
          <img
            src={`${footerSprite}#${item.carrier_code}`}
            alt={item.carrier_description}
            width="155"
            height={getHeight(item.carrier_code)}
          />
        </div>
      );
    });
  }

  return carriersList;
};
