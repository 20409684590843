import React from 'react';
import { Link } from 'react-router-dom';
import footerSprite from '../../../../../assets/images/sprites/footerSprites.svg';
import Container from '../../layout/Container/Container';
import { NavigationFooterTracking } from '../../elements/Tracking/GoogleTagManager';
import styles from './Footer.module.scss';

export const FooterSecondaryVariant = ({ storeConfiguration, t }) => {
  const clickFooterLink = e => {
    NavigationFooterTracking({ storeConfiguration, label: `${e.target.dataset.category} - ${e.target.innerText}` });
  };
  const { phone } = storeConfiguration?.general?.store_information;
  return (
    <Container>
      <div>
        <div className={styles.FooterImpressum}>
          <div>
            <Link onClick={clickFooterLink} data-category="General" to={`/${t('cms.imprint.link')}`} target="_blank">
              {t('cms.imprint.label')}
            </Link>
          </div>

          <img src={`${footerSprite}#trustedshops`} className={styles.TrustedShopsLogo} alt="TrustedShops" />

          <div className={styles.FooterImpressumLinks}>
            <span>{t('footer.address')}</span>
            <span>ASAMBEAUTY GmbH</span>
            <span>Feringastraße 12a</span>
            <span>85774 Unterföhring</span>
            <a href={`mailto:${t('footer.mail')}`}>{t('footer.mail')}</a>
            {phone ? (
              <p>
                {t('footer.phone')}
                <a href={`tel:${phone}`}>{phone}</a>
              </p>
            ) : null}
          </div>
        </div>
      </div>
    </Container>
  );
};
