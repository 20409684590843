import React from 'react';
import { T } from '@deity/falcon-i18n';
import { Link } from 'react-router-dom';
import { Locale } from '@deity/falcon-front-kit';
import { NavigationFooterTracking } from '../../elements/Tracking/GoogleTagManager/GA4/Navigation/Footer';
import styles from './Footer.module.scss';

export const FooterPrivacyText = ({ storeConfiguration, t }) => {
  const clickFooterLink = e => {
    NavigationFooterTracking({ storeConfiguration, label: `${e.target.dataset.category} - ${e.target.innerText}` });
  };

  return (
    <Locale>
      {localeContext => {
        const price = new Intl.NumberFormat(localeContext.locale, {
          style: 'currency',
          currency: localeContext.currency,
          minimumFractionDigits: 0
        }).format(storeConfiguration.asam_shippinginformation.general.free_shipping_amount);
        return (
          <div className={styles.FooterInfo}>
            <p>
              <T id="footer.info" freeShippingAmount={price} />
              {t('footer.infoLink') === 'footer.infoLink' ? null : (
                <Link
                  onClick={clickFooterLink}
                  data-category="General"
                  to={`/${t('cms.deliveryshipping.link')}`}
                  target="_blank"
                >
                  {t('footer.infoLink')}
                </Link>
              )}
              .
            </p>
            <p>
              {t('contactForm.contactUs')}: <a href={`mailto:${t('footer.mail')}`}>{t('footer.mail')}</a>
            </p>
          </div>
        );
      }}
    </Locale>
  );
};
