import React from 'react';
import { Helmet } from 'react-helmet-async';
import withStoreConfiguration from '../../../../helpers/StoreConfiguration/StoreConfiguration';
import useCheckBreakpoints from '../../../../hooks/CheckBreakpoint';
import styles from './CmsImages.module.scss';

export const CmsImage = withStoreConfiguration(
  ({
    storeConfiguration,
    hideMobile,
    largeImage,
    smallImage,
    imageAlt,
    imgCopyright,
    useHeight = true,
    useWidth = true,
    largeImageWidth,
    largeImageHeight,
    smallImageWidth,
    smallImageHeight,
    aspectRatioLargeWidth,
    aspectRatioLargeHeight,
    aspectRatioSmallWidth,
    aspectRatioSmallHeight,
    fetchPriority
  }) => {
    // For debug, add 'source' to parameters and console.log it in order to see where the image comes from.
    const { isDesktop, isMobile, isTablet } = useCheckBreakpoints();
    let imageWidth = null;
    let imageHeight = null;
    if (imageAlt) {
      if (imageAlt.description) {
        imageAlt = imageAlt.description;
      }
      if (!imageAlt.description && imageAlt.title) {
        imageAlt = imageAlt.title;
      }
    }

    const allowWebP =
      storeConfiguration &&
      storeConfiguration.asam_contentful &&
      storeConfiguration.asam_contentful.images &&
      storeConfiguration.asam_contentful.images.webp_images === '1';

    let image = null,
      webpImage = null,
      webpLargeImage = null,
      webpSmallImage = null;

    if (isDesktop) {
      image = largeImage;
      imageWidth = largeImageWidth;
      imageHeight = largeImageHeight;
    }

    if (isTablet || isMobile) {
      image = smallImage || largeImage;
      imageWidth = smallImageWidth || largeImageWidth;
      imageHeight = smallImageHeight || largeImageHeight;
    }

    if (
      largeImage &&
      (largeImage.toLowerCase().indexOf('.jpg') !== -1 || largeImage.toLowerCase().indexOf('.png') !== -1)
    ) {
      webpLargeImage = `${largeImage}?fm=webp`;

      if (useHeight && imageHeight) {
        webpLargeImage += `&h=${imageHeight}`;
      }
      if (useWidth && imageWidth) {
        webpLargeImage += `&w=${imageWidth}`;
      }
    }

    if (
      (smallImage && smallImage.toLowerCase().indexOf('.jpg') !== -1) ||
      (smallImage && smallImage.toLowerCase().indexOf('.png') !== -1)
    ) {
      webpSmallImage = `${smallImage}?fm=webp`;

      if (useHeight && imageHeight) {
        webpSmallImage += `&h=${imageHeight}`;
      }
      if (useWidth && imageWidth) {
        webpSmallImage += `&w=${imageWidth}`;
      }
    }

    if (webpLargeImage || webpSmallImage) {
      webpImage = webpSmallImage || webpLargeImage;
    }

    return (
      <>
        <div className={[hideMobile && 'HideMobile', styles.CmsImageHolder].join(' ')}>
          <picture>
            {allowWebP && webpImage ? (
              <>
                <source
                  media="(max-width: 767px)"
                  srcSet={webpSmallImage || webpLargeImage}
                  type="image/webp"
                  width={aspectRatioSmallWidth || aspectRatioLargeWidth}
                  height={aspectRatioSmallHeight || aspectRatioLargeHeight}
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={webpLargeImage}
                  type="image/webp"
                  width={aspectRatioLargeWidth}
                  height={aspectRatioLargeHeight}
                />
              </>
            ) : (
              <>
                <source
                  media="(max-width: 767px)"
                  srcSet={smallImage || largeImage}
                  width={aspectRatioSmallWidth || aspectRatioLargeWidth}
                  height={aspectRatioSmallHeight || aspectRatioLargeHeight}
                />
                <source
                  media="(min-width: 768px)"
                  srcSet={largeImage}
                  width={aspectRatioLargeWidth}
                  height={aspectRatioLargeHeight}
                />
              </>
            )}
            <img
              className={styles.CmsImage}
              src={image}
              alt={imageAlt || null}
              loading={fetchPriority ? `eager` : 'lazy'}
              fetchpriority={fetchPriority ? 'high' : 'auto'}
            />
          </picture>
        </div>
        {imgCopyright ? <small className={hideMobile && 'HideMobile'}>{imgCopyright}</small> : null}
        {fetchPriority ? (
          <Helmet>
            <link
              rel="preload"
              fetchpriority="high"
              as="image"
              href={allowWebP && webpImage ? webpSmallImage : smallImage}
              type={allowWebP && webpImage ? 'image/webp' : 'image/jpeg'}
              media="max-width: 767px"
            />
            <link
              rel="preload"
              fetchpriority="high"
              as="image"
              href={allowWebP && webpImage ? webpLargeImage : largeImage}
              type={allowWebP && webpImage ? 'image/webp' : 'image/jpeg'}
              media="min-width: 768px"
            />
          </Helmet>
        ) : null}
      </>
    );
  }
);
