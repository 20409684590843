import React from 'react';
import footerSprite from '../../../../../assets/images/sprites/footerSprites.svg';
import styles from './Footer.module.scss';

export const FooterPayment = ({ cardIcons, paymentsObj, storeCode }) => {
  const paymentList = [];

  paymentList.push(
    <ul className={styles.PaymentIconList} key="footerPaymentList">
      {paymentsObj.stripe_payments && paymentsObj.stripe_payments.active === '1' && cardIcons && (
        <>
          {cardIcons.includes('mastercard') && (
            <li key="mastercard">
              <img src={`${footerSprite}#mastercard`} alt="MasterCard" width="53" height="32" />
            </li>
          )}
          {cardIcons.includes('visa') && (
            <li key="visa">
              <img src={`${footerSprite}#visa`} alt="Visa" width="53" height="32" />
            </li>
          )}
          {storeCode === 'fr-FR' && cardIcons.includes('visa') && (
            <li key="visaFR">
              <img src={`${footerSprite}#cardbancaire`} alt="Carte Bancaire" width="53" height="32" />
            </li>
          )}
          {cardIcons.includes('amex') && (
            <li key="amex">
              <img src={`${footerSprite}#amex`} alt="Amex" width="53" height="32" />
            </li>
          )}
        </>
      )}
      {paymentsObj.paypal && paymentsObj.paypal.active === '1' && (
        <li key="paypal">
          <img src={`${footerSprite}#paypal`} alt="PayPal" width="53" height="32" />
        </li>
      )}
      {paymentsObj.amazon_payment_v2 && paymentsObj.amazon_payment_v2.active === '1' && (
        <li key="AmazonPay">
          <img src={`${footerSprite}#amazon`} alt="AmazonPay" width="53" height="32" />
        </li>
      )}
      {paymentsObj.payu_gateway && paymentsObj.payu_gateway.active === '1' && (
        <>
          <li key="PayU">
            <img src={`${footerSprite}#payu`} alt="PayU" width="53" height="32" />
          </li>
          {cardIcons && paymentsObj.stripe_payments && paymentsObj.stripe_payments.active === '0' && (
            <>
              {cardIcons.includes('mastercard') && (
                <li key="mastercardStripe">
                  <img src={`${footerSprite}#mastercard`} alt="MasterCard" width="53" height="32" />
                </li>
              )}
              {cardIcons.includes('visa') && (
                <li key="visaStripe">
                  <img src={`${footerSprite}#visa`} alt="Visa" width="53" height="32" />
                </li>
              )}
              {cardIcons.includes('amex') && (
                <li key="amexStripe">
                  <img src={`${footerSprite}#amex`} alt="Amex" width="53" height="32" />
                </li>
              )}
            </>
          )}
        </>
      )}
      {paymentsObj.stripe_payments_p24 && paymentsObj.stripe_payments_p24.active === '1' && (
        <li key="Przelewy24">
          <img src={`${footerSprite}#przelewy_24`} alt="Przelewy 24" width="53" height="32" />
        </li>
      )}
      {paymentsObj.invoice && paymentsObj.invoice.active === '1' && storeCode !== 'fr-FR' && (
        <li key="rechnung">
          <img src={`${footerSprite}#rechnung`} alt="Rechnung" width="53" height="32" />
        </li>
      )}
      {paymentsObj.checkmo && paymentsObj.checkmo.active === '1' && (
        <li key="vorkasse">
          <img src={`${footerSprite}#vorkasse`} alt="Vorkasse" width="53" height="32" />
        </li>
      )}
    </ul>
  );

  return paymentList;
};
