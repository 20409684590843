import React from 'react';
// import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';

const SmartBannerIos = withStoreConfiguration(({ storeConfiguration }) => {
  // TODO: uncomment below as soon as useStoreContext is available
  /* const { getStoreConfigValue, getStoreConfigFlag } = useStoreContext();
  const smartBannerEnabled = getStoreConfigFlag('asam_mobile.smart_banner_ios.enabled');
  const smartBannerId = getStoreConfigValue('asam_mobile.smart_banner_ios.app_id'); */

  // TODO: delete below as soon as above is available
  const smartBannerEnabled = !!parseInt(storeConfiguration?.asam_mobile?.smart_banner_ios?.enabled || 0, 10);
  const smartBannerId = storeConfiguration?.asam_mobile?.smart_banner_ios?.app_id?.trim() || '';
  // up to here

  const prefix = storeConfiguration?.asam_mobile?.smart_banner?.sb_prefix_ios || 'com.asambeauty.de:/';
  /*
  const location = useLocation();
  const [url, setUrl] = useState(prefix + location.pathname + location.search);
  useEffect(() => {
    setUrl(prefix + location.pathname + location.search);
  }, [location]);
  */
  return smartBannerEnabled && smartBannerId ? (
    <Helmet>
      <meta name="apple-itunes-app" content={`app-id=${smartBannerId},app-argument=${prefix}`} />
    </Helmet>
  ) : null;
});

export default SmartBannerIos;
