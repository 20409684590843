import React from 'react';
import { useGlobalStateValue } from '../../helpers/GlobalState/GlobalState';
import { Notification } from './Notification/Notification';

const Notifications = props => {
  const [{ messages }, dispatch] = useGlobalStateValue();

  const removeMessageHandle = id => {
    dispatch({
      type: 'REMOVE_FROM_STATE',
      section: 'messages',
      id
    });
  };

  const updateMessageHandle = data => {
    dispatch({
      type: 'UPDATE_IN_STATE',
      section: 'messages',
      data
    });
  };

  return (
    <div>
      {props.children}
      {messages ? (
        <div>
          {messages.map(item => (
            <Notification
              key={`message-${item.uniqueId}`}
              message={item}
              updateMessage={updateMessageHandle}
              removeMessage={removeMessageHandle}
            />
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default Notifications;
