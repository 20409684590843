import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { canUseDOM } from 'exenv';
import Cookies from 'js-cookie';
import window from 'global';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import Container from '../../layout/Container/Container';
import { Button } from '../../ui/Button/Button';
import { isAppMobile } from '../../helpers/AppMobile/AppMobile';
import styles from './CookieConsent.module.scss';

const CookieConsent = withStoreConfiguration(({ storeConfiguration }) => {
  const [cookieAccepted, setCookieAccepted] = useState(false);
  const enableModule = parseInt(storeConfiguration?.asam_cookieconsent?.general?.is_active, 10);
  const acceptCookieHandler = () => {
    Cookies.set('cookieConsentAgreed', true, { expires: 365 });
    setCookieAccepted(true);
  };

  const cookieConsentHandler = event => {
    if (event.detail && event.detail.event === 'consent_status')
      Cookies.set(
        'CookieConsent',
        `essential:${event.detail?.ucCategory?.essential},marketing:${event.detail?.ucCategory?.marketing},functional:${event.detail?.ucCategory?.functional}`,
        { expires: 365 }
      );
  };

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('ucEvent', cookieConsentHandler);
      return () => window.removeEventListener('ucEvent', cookieConsentHandler);
    }
  }, []);

  useEffect(() => {
    const hideUCPopupIfAppMobile = () => {
      if (window.UC_UI?.isInitialized()) {
        window.UC_UI?.closeCMP();
        localStorage.setItem('uc_user_interaction', true);
      }
    };

    if (isAppMobile) {
      if (typeof window !== 'undefined') {
        window.addEventListener('UC_UI_INITIALIZED', hideUCPopupIfAppMobile);
        return () => window.removeEventListener('UC_UI_INITIALIZED', hideUCPopupIfAppMobile);
      }
    }
  }, []);

  if (canUseDOM && enableModule && !cookieAccepted && Cookies.get('cookieConsentAgreed') === undefined) {
    return (
      <div>
        <div className={styles.CookieConsent}>
          <Container>
            <div className={styles.Content}>
              <span>{storeConfiguration?.asam_cookieconsent?.general?.text}</span>
              <Link to={storeConfiguration?.asam_cookieconsent?.general?.privacy_url}>
                {storeConfiguration?.asam_cookieconsent?.general?.privacy_text}
              </Link>
              <Button size="small" shadow={false} onClick={acceptCookieHandler}>
                {storeConfiguration?.asam_cookieconsent?.general?.confirm_text}
              </Button>
            </div>
          </Container>
        </div>
      </div>
    );
  }
  return null;
});

export default CookieConsent;
