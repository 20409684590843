import React, { useEffect, useRef } from 'react';
import { T } from '@deity/falcon-i18n';
import { UrlQuery } from '@deity/falcon-data';
import { Loader } from '@deity/falcon-ui-kit';
import { CmsPageQuery } from '../../elements/Cms/CmsQuery';
import loadable from '../../../../../components/loadable';
import styles from './Modal.module.scss';

const Row = loadable(() => import(/* webpackChunkName: "cmsWidgets" */ '../../elements/Cms/Row'));

const Modal = ({
  content,
  visible,
  close,
  variant,
  showCloseLabel,
  scrollableContent,
  pageContent,
  autohide,
  hideClose,
  disableBgClose,
  open
}) => {
  const contentWrapper = useRef();
  const additionalClasses = [];

  if (variant === 'secondary') {
    additionalClasses.push(styles.ModalSecondary);
  }

  if (variant === 'tertiary') {
    additionalClasses.push(styles.ModalTertiary);
  }

  if (variant === 'quaternary') {
    additionalClasses.push(styles.ModalQuaternary);
  }

  if (variant === 'quinary') {
    additionalClasses.push(styles.ModalQuinary);
  }

  if (variant === 'senary') {
    additionalClasses.push(styles.ModalSenary);
  }

  if (variant === 'lightbox') {
    additionalClasses.push(styles.ModalLightbox);
  }

  if (variant === 'aboCheckout') {
    additionalClasses.push(styles.ModalAboCheckout);
  }

  if (variant === 'aboCheckoutSuccess') {
    additionalClasses.push(styles.ModalAboCheckoutSuccess);
  }

  if (variant === 'wrapContent') {
    additionalClasses.push(styles.ModalWrapContent);
  }

  if (variant === 'centered') {
    additionalClasses.push(styles.ModalCentered);
  }

  if (scrollableContent) {
    additionalClasses.push(styles.ModalScrollableContent);
  }

  const resetModalView = () => {
    contentWrapper.current.scrollTo(0, 0);
  };

  const resetViewAndClose = () => {
    resetModalView();
    close();
  };

  const closeModal = hideClose ? null : (
    <div className={styles.ModalClose} onClick={resetViewAndClose} aria-hidden>
      {showCloseLabel ? <T id="modal.close" /> : null}
    </div>
  );

  const backModal = (
    <div className={styles.ModalBack} onClick={resetViewAndClose} aria-hidden>
      <T id="modal.back" />
    </div>
  );

  const animationStart = () => {
    resetModalView();
  };

  useEffect(() => {
    resetModalView();
  }, [visible]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.addEventListener('modalUpdate', resetModalView);

      return () => window.removeEventListener('modalUpdate', resetModalView);
    }
  }, []);

  const popupContent = (
    <React.Fragment>
      {!scrollableContent ? closeModal : null}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
      <div className={styles.ModalContentWrapper} ref={contentWrapper} onAnimationStart={animationStart}>
        {!pageContent ? (
          content
        ) : (
          <UrlQuery variables={{ path: `/${pageContent}` }}>
            {data => (
              <CmsPageQuery variables={{ id: data.data.url.id }}>
                {({ data: { contentfulPage }, loading }) => {
                  if (loading) {
                    return <Loader />;
                  }
                  return (
                    <React.Fragment>
                      {contentfulPage.contentData.modules.map((item, index) => (
                        <Row
                          key={`Row -'${index}'`} // eslint-disable-line
                          item={item.fields}
                          contentType={item.sys.contentType.sys.id}
                        />
                      ))}
                    </React.Fragment>
                  );
                }}
              </CmsPageQuery>
            )}
          </UrlQuery>
        )}
      </div>
    </React.Fragment>
  );

  if (autohide && visible) {
    setTimeout(() => {
      close();
    }, 4000);
  }

  return (
    <div onLoad={open} className={[styles.Modal, visible ? styles.Active : null, ...additionalClasses].join(' ')}>
      <div
        className={[styles.ModalBackdrop, disableBgClose ? styles.ModalBackdropDisableClose : null].join(' ')}
        onClick={disableBgClose ? null : close}
        aria-hidden
      />
      <div className={[styles.ModalContent, pageContent ? styles.ModalPageContent : null].join(' ')}>
        {backModal}
        {scrollableContent ? closeModal : null}
        {popupContent}
      </div>
    </div>
  );
};

export default Modal;

Modal.defaultProps = {
  variant: 'primary'
};
