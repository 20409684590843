import React, { Component } from 'react';
import { debounce } from 'lodash';
import styles from './ScrollToTopButton.module.scss';

class ScrollToTopButton extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showScrollToTop: false
    };

    this.handleScrollUpdate = debounce(this.handleScrollUpdate.bind(this), 100);
  }

  componentDidMount() {
    this.handleScrollUpdate();
    window.addEventListener('scroll', this.handleScrollUpdate);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScrollUpdate);
  }

  handleScrollUpdate() {
    this.setState({
      showScrollToTop: window && window.pageYOffset > 530
    });
  }

  scrollToTop() {
    document?.getElementById('top')?.scrollIntoView({ behavior: 'smooth' });
  }

  render() {
    return this.state.showScrollToTop ? (
      <div className={styles.ScrollToTopButton} onClick={this.scrollToTop} aria-hidden />
    ) : null;
  }
}

export default ScrollToTopButton;
