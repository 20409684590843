import React from 'react';
import { Image } from '@deity/falcon-ui';
import { useLocation } from 'react-router-dom';
import { LazyLoad } from '../../../../helpers/LazyLoad/LazyLoad';
import { YouTubeNoCookie } from '../../../YouTubeNoCookie/YouTubeNoCookie';
import useYouTubePlaceholder from '../../../../hooks/YouTubePlaceholder';
import styles from './CmsVideo.module.scss';

export const CmsVideo = ({ videoId, videoCopyright }) => {
  function removeOverlay() {
    const videoContainer = document.getElementById(`${videoId}`);
    videoContainer.classList.add(styles.Active);
  }

  const { loadPlaceholder } = useYouTubePlaceholder();

  const query = new URLSearchParams(useLocation().search);
  const trackingConsent = query.get('trackingConsent');

  if (trackingConsent === 'false') {
    return null;
  }

  return (
    <LazyLoad>
      {videoId ? (
        <React.Fragment>
          <div
            className={[styles.VideoHeight, loadPlaceholder ? styles.VideoHeightYouTube : ''].join(' ')}
            id={videoId}
            onMouseEnter={() => removeOverlay()}
          >
            <>
              {!loadPlaceholder ? null : (
                <div className={styles.VideoOverlay}>
                  <div className={[styles.VideoLogoWrapper, 'icon-youtube'].join(' ')} />
                  <Image src={`https://img.youtube.com/vi/${videoId}/maxresdefault.jpg`} />
                </div>
              )}

              <YouTubeNoCookie videoId={videoId} className={styles.CmsVideo} />
            </>
          </div>
          {videoCopyright ? <small>{videoCopyright}</small> : null}
        </React.Fragment>
      ) : null}
    </LazyLoad>
  );
};
