import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Container from '../../../layout/Container/Container';
import styles from './Notification.module.scss';

export const Notification = ({ message, removeMessage, updateMessage }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      updateMessage({ ...message, animate: true });
    }, message.timeOfVisibility || 5000);
    return () => clearTimeout(timer);
  }, []);

  const additionalClasses = [];

  if (message.animate) {
    additionalClasses.push(styles.NotificationAnimate);
  }

  switch (message.type) {
    case 'error':
      additionalClasses.push(styles.NotificationError);
      break;
    case 'success':
      additionalClasses.push(styles.NotificationSuccess);
      break;
    case 'warning':
      additionalClasses.push(styles.NotificationWarning);
      break;
    case 'info':
      additionalClasses.push(styles.NotificationInfo);
      break;
    default:
      additionalClasses.push(styles.NotificationInfo);
  }

  return (
    <div
      className={[styles.Notification, ...additionalClasses].join(' ')}
      onAnimationEnd={() => removeMessage(message.uniqueId)}
    >
      <Container>
        <p>{message.content}</p>
      </Container>
      <div
        className={[styles.NotificationClose, 'icon-x'].join(' ')}
        onClick={() => removeMessage(message.uniqueId)}
        aria-hidden
      />
    </div>
  );
};

Notification.propTypes = {
  /** Message component content */
  message: PropTypes.shape({
    /** Message content */
    content: PropTypes.string,
    /** Message id */
    uniqueId: PropTypes.string,
    /** Message type: error, success, warning, info (default is info) */
    type: PropTypes.oneOf(['error', 'success', 'warning', 'info']),
    /** Message animation */
    animate: PropTypes.bool,
    /** Message expiration time */
    timeOfVisibility: PropTypes.number
  })
};

Notification.defaultProps = {
  message: {
    type: 'info'
  }
};
