import React from 'react';
import { Link } from 'react-router-dom';
import { NavigationFooterTracking } from '../../elements/Tracking/GoogleTagManager/GA4/Navigation/Footer';
import styles from './Footer.module.scss';

export const clickConsentLink = e => {
  if (typeof window !== 'undefined' && typeof window?.UC_UI !== 'undefined' && !window?.UC_UI?.isConsentRequired()) {
    e.preventDefault();
    window.UC_UI?.showFirstLayer();
  }
};
export const FooterPrivacyLinks = ({ storeCode, storeConfiguration, t }) => {
  const clickFooterLink = e => {
    NavigationFooterTracking({ storeConfiguration, label: `${e.target.dataset.category} - ${e.target.innerText}` });
  };

  return (
    <ul
      className={[styles.FooterAdditionalList, storeCode === 'fr-FR' ? styles.FooterAdditionalListCropped : null].join(
        ' '
      )}
    >
      <li className={styles.FooterAdditionalListItem}>
        <Link onClick={clickFooterLink} data-category="General" to={`/${t('cms.imprint.link')}`}>
          {t('cms.imprint.label')}
        </Link>
      </li>
      <li className={styles.FooterAdditionalListItem}>
        <Link onClick={clickFooterLink} data-category="General" to={`/${t('cms.dataprotection.link')}`}>
          {t('cms.dataprotection.label')}
        </Link>
      </li>
      <li className={styles.FooterAdditionalListItem}>
        <Link onClick={clickFooterLink} data-category="General" to={`/${t('cms.trackingAndSocialMedia.link')}`}>
          {t('cms.trackingAndSocialMedia.label')}
        </Link>
      </li>
      <li className={styles.FooterAdditionalListItem}>
        <Link onClick={clickFooterLink} data-category="General" to={`/${t('cms.agb.link')}`}>
          {t('cms.agb.label')}
        </Link>
      </li>
      <li className={styles.FooterAdditionalListItem}>
        <Link onClick={clickFooterLink} data-category="General" to={`/${t('cms.revocation.link')}`}>
          {t('cms.revocation.label')}
        </Link>
      </li>
      <li className={styles.FooterAdditionalListItem}>
        <Link onClick={clickConsentLink} data-category="General" to={`/${t('cms.cookies-setup.link')}`}>
          {t('cms.cookies-setup.label')}
        </Link>
      </li>
    </ul>
  );
};
