import React, { useState } from 'react';
import { useAddToCartMutation } from '@deity/falcon-shop-data';
import { useGetUserError } from '@deity/falcon-data';
import { AddedToCartPopup } from '../Product';
import { AddedToCartErrorPopup } from '../Product/AddedToCartPopup/AddedToCartErrorPopup';

const ChatChampAddProduct = () => {
  const [addToCart] = useAddToCartMutation();
  const [getUserError] = useGetUserError();
  const [showAddedToCart, setShowAddedToCart] = useState(false);
  const [showAddedToCartErrorPopup, setShowAddedToCartErrorPopup] = useState(false);
  const [addedToCartError, setAddedToCartError] = useState([]);

  if (typeof window !== 'undefined') {
    // eslint-disable-next-line func-names
    window.addProduct = function(productId, quantity) {
      if (productId && quantity) {
        addToCart({
          variables: {
            input: {
              sku: productId,
              qty: parseInt(quantity.toString(), 10),
              options: [],
              bundleOptions: undefined,
              customOptions: []
            }
          }
        })
          .then(() => {
            setShowAddedToCart(true);
          })
          .catch(e => {
            const error = getUserError(e);
            setShowAddedToCartErrorPopup(true);
            if (error.length) {
              setAddedToCartError(error);
            }
          });
      }
    };
  }

  return (
    <>
      <AddedToCartPopup on={showAddedToCart} toggle={() => setShowAddedToCart(false)} />
      {showAddedToCartErrorPopup && (
        <AddedToCartErrorPopup
          on={showAddedToCartErrorPopup}
          toggle={() => setShowAddedToCartErrorPopup(false)}
          message={addedToCartError}
        />
      )}
    </>
  );
};

export default ChatChampAddProduct;
