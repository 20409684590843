import React from 'react';
import { I18n } from '@deity/falcon-i18n';
import Container from '../../layout/Container/Container';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import svgSprite from '../../../../../assets/images/sprites/sprites.svg';
import styles from './PreFooter.module.scss';

const PreFooter = withStoreConfiguration(({ storeConfiguration }) => {
  const storeCode = storeConfiguration?.general?.country?.default;
  const minDelivery = storeConfiguration?.asam_shippinginformation?.general?.min_delivery;
  const maxDelivery = storeConfiguration?.asam_shippinginformation?.general?.max_delivery;
  return (
    <I18n>
      {t => (
        <Container>
          <div className={styles.PreFooter}>
            <div className={styles.PreFooterIcon}>
              <img src={`${svgSprite}#preFooterSafety`} alt={t('prefooter.payment')} width="48" height="48" />
              <p className={styles.PreFooterIconText}>{t('prefooter.payment')}</p>
            </div>
            <div className={styles.PreFooterIcon}>
              <img src={`${svgSprite}#preFooterDelivery`} alt={t('prefooter.shipping')} width="48" height="48" />
              <p className={styles.PreFooterIconText}>
                {t('prefooter.shipping', {
                  minDelivery,
                  maxDelivery
                })}
              </p>
            </div>
            <div className={styles.PreFooterIcon}>
              <img src={`${svgSprite}#preFooterRefund`} alt={t('prefooter.return')} width="48" height="48" />
              <p className={styles.PreFooterIconText}>{t('prefooter.return')}</p>
            </div>
            {storeCode !== 'FR' && storeCode !== 'US' && storeCode !== 'PL' && (
              <div className={styles.PreFooterIcon}>
                <img src={`${svgSprite}#preFooterBuy`} alt={t('prefooter.buy')} width="48" height="48" />
                <p className={styles.PreFooterIconText}>{t('prefooter.buy')}</p>
              </div>
            )}
          </div>
        </Container>
      )}
    </I18n>
  );
});

export default PreFooter;
