import React from 'react';
import footerSprite from '../../../../../assets/images/sprites/footerSprites.svg';
import styles from './Footer.module.scss';

export const FooterApps = ({ storeConfiguration }) => {
  // TODO: uncomment below as soon as useStoreContext is available
  /* const { getStoreConfigValue, getStoreConfigFlag } = useStoreContext();
  const iosAppEnabled = getStoreConfigFlag('asam_mobile.appstore.enabled');
  const iosAppUrl = getStoreConfigValue('asam_mobile.appstore.url');
  const androidAppEnabled = getStoreConfigFlag('asam_mobile.google_play.enabled');
  const androidAppUrl = getStoreConfigValue('asam_mobile.google_play.url'); */

  // TODO: delete below as soon as above is available
  const iosAppEnabled = !!parseInt(storeConfiguration?.asam_mobile?.appstore?.enabled || 0, 10);
  const iosAppUrl = storeConfiguration?.asam_mobile?.appstore?.url;
  const androidAppEnabled = !!parseInt(storeConfiguration?.asam_mobile?.google_play?.enabled || 0, 10);
  const androidAppUrl = storeConfiguration?.asam_mobile?.google_play?.url;

  return (iosAppEnabled && iosAppUrl) || (androidAppEnabled && androidAppUrl) ? (
    <div className={styles.AppSection}>
      <ul className={styles.AppIconsList}>
        {iosAppEnabled && iosAppUrl ? (
          <li>
            <a href={iosAppUrl} title="Laden im App Store Asambeauty App" target="_blank" rel="noopener noreferrer">
              <img src={`${footerSprite}#iosApp`} width="120" height="40" alt="App Store Asambeauty App" />
            </a>
          </li>
        ) : null}
        {androidAppEnabled && androidAppUrl ? (
          <li>
            <a
              href={androidAppUrl}
              title="Laden im Google Play Asambeauty App"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={`${footerSprite}#androidApp`} width="135" height="40" alt="Google Play Asambeauty App" />
            </a>
          </li>
        ) : null}
      </ul>
    </div>
  ) : null;
};
