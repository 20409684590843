import React from 'react';
import { Link } from 'react-router-dom';
import { I18n } from '@deity/falcon-i18n';
import { useQuery } from '@deity/falcon-data';
import { CustomerQuery } from '../../overrides/Customer/CustomerQuery';
import Container from '../../layout/Container/Container';
import { Multiselect } from '../../ui/Multiselect/Multiselect';
import footerSprite from '../../../../../assets/images/sprites/footerSprites.svg';
import { GET_BACKEND_CONFIG } from '../../overrides/BackendConfig/BackendConfigQuery';
import { CustomerWithAddressesQuery } from '../../overrides/Customer/CustomerWithAddressesQuery';
import { NavigationFooterTracking, PageMetaTracking, OutboundLink } from '../../elements/Tracking/GoogleTagManager';
import withStoreConfiguration from '../../helpers/StoreConfiguration/StoreConfiguration';
import BambuserPlayer from '../../elements/Bambuser/BambuserPlayer';
import { WebVitals } from '../../helpers/ExternalScripts/ExternalScripts';
import useCheckBreakpoints from '../../hooks/CheckBreakpoint';
import { GoogleTagManager4Customer } from '../../elements/Tracking/GoogleTagManager/GA4/Customer';
import ChatChampAddProduct from '../../elements/ChatChampAddProduct/ChatChampAddProduct';
import styles from './Footer.module.scss';
import { FooterCarriers } from './FooterCarriers';
import { FooterPayment } from './FooterPayment';
import { FooterSocials } from './FooterSocials';
import { FooterPrivacyLinks } from './FooterPrivacyLinks';
import { FooterPrivacyText } from './FooterPrivacyText';
import { FooterSecondaryVariant } from './FooterSecondaryVariant';
import { FooterApps } from './FooterApps';

const Footer = withStoreConfiguration(({ storeConfiguration, secondary }) => {
  const { isDesktop } = useCheckBreakpoints();
  const headers = [];
  const contents = [];
  const { data } = useQuery(GET_BACKEND_CONFIG);
  const stores =
    storeConfiguration?.asam_store?.language_switch?.external_systems &&
    Object.values(JSON.parse(storeConfiguration.asam_store.language_switch.external_systems));
  const storeCode = data ? data.backendConfig.activeLocale : 'de-DE';
  const cardIcons = storeConfiguration?.payment?.stripe_payments?.card_icons_specific?.split(',');
  const trustedShopActive = parseInt(storeConfiguration?.asam_layoutconfiguration?.trustedshop?.active, 10);
  const hotlineActive = parseInt(storeConfiguration?.contact?.hotline?.active, 10);
  const carriers = storeConfiguration?.asam_shipping?.carrier?.carriers;
  const paybackActive = storeConfiguration?.payback?.general?.enabled === '1';

  // set default url in case config is empty
  let trustedLink = 'https://www.trustedshops.de/bewertung/info_XD44DBE0B47337B705AEE09C7FD01632A.html';
  if (trustedShopActive && storeConfiguration?.asam_layoutconfiguration?.trustedshop?.url) {
    trustedLink = storeConfiguration.asam_layoutconfiguration.trustedshop.url;
  }

  const clickFooterLink = e => {
    NavigationFooterTracking({ storeConfiguration, label: `${e.target.dataset.category} - ${e.target.innerText}` });
  };

  const languageSwitcherLinks = (
    <I18n>
      {t => (
        <ul className={styles.FooterLinksList}>
          {stores &&
            stores.map((store, i) => {
              if (store.storecode === 'asambeauty_eur_de') {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className={styles.FooterLinksListItem} key={`FooterLanguage-${i}`}>
                    <a href="/">{t('languageSwitcher.DE')}</a>
                  </li>
                );
              }
              if (store.storecode === 'asambeauty_fra_fr') {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li className={styles.FooterLinksListItem} key={`FooterLanguage-${i}`}>
                    <a href="/fr/">{t('languageSwitcher.FR')}</a>
                  </li>
                );
              }
              if (store.storecode === 'asambeauty_chf_ch') {
                return (
                  // eslint-disable-next-line react/no-array-index-key
                  <li rel="alternate" className={styles.FooterLinksListItem} key={`FooterLanguage-${i}`}>
                    <a href="/de_ch/">{t('languageSwitcher.CH')}</a>
                  </li>
                );
              }
              return null;
            })}
        </ul>
      )}
    </I18n>
  );

  headers.push(<I18n>{t => <div className={styles.FooterGroupTitle}>{t('footer.aboutAsam')}</div>}</I18n>);
  headers.push(<I18n>{t => <div className={styles.FooterGroupTitle}>{t('mobileMenu.customer')}</div>}</I18n>);
  headers.push(
    <I18n>
      {t => <div className={`${styles.FooterGroupTitle} ShowTablet ShowMobile`}>{t('languageSwitcher.title')}</div>}
    </I18n>
  );
  headers.push(<I18n>{t => <div className={styles.FooterGroupTitle}>{t('footer.delivery')}</div>}</I18n>);
  headers.push(<I18n>{t => <div className={styles.FooterGroupTitle}>{t('footer.easilyPay')}</div>}</I18n>);
  headers.push(
    <I18n>
      {t => <div className={`${styles.FooterGroupTitle} ShowTablet ShowMobile`}>{t('footer.safeShopping')}</div>}
    </I18n>
  );

  contents.push(
    <I18n>
      {t => (
        <div className={styles.FooterGroupContent}>
          <ul className={styles.FooterLinksList}>
            <BambuserPlayer />
            {t('cms.brand.link') === 'cms.brand.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.brand.link')}`}>
                  {t('cms.brand.label')}
                </Link>
              </li>
            )}
            {t('cms.sustainability.link') === 'cms.sustainability.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.sustainability.link')}`}>
                  {t('cms.sustainability.label')}
                </Link>
              </li>
            )}
            {t('cms.magazin.link') === 'cms.magazin.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.magazin.link')}`}>
                  {t('cms.magazin.label')}
                </Link>
              </li>
            )}
            {t('cms.benefits.link') === 'cms.benefits.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.benefits.link')}`}>
                  {t('cms.benefits.label')}
                </Link>
              </li>
            )}
            {t('cms.technologies.link') === 'cms.technologies.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.technologies.link')}`}>
                  {t('cms.technologies.label')}
                </Link>
              </li>
            )}
            {t('cms.ingredients.link') === 'cms.ingredients.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.ingredients.link')}`}>
                  {t('cms.ingredients.label')}
                </Link>
              </li>
            )}
            {t('cms.history.link') === 'cms.history.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.history.link')}`}>
                  {t('cms.history.label')}
                </Link>
              </li>
            )}
            {t('cms.drugstore.link') === 'cms.drugstore.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.drugstore.link')}`}>
                  {t('cms.drugstore.label')}
                </Link>
              </li>
            )}
            {t('cms.career.extLink') === 'cms.career.extLink' ? null : (
              <li className={styles.FooterLinksListItem}>
                <a onClick={clickFooterLink} data-category="About" href={t('cms.career.extLink')}>
                  {t('cms.career.label')}
                </a>
              </li>
            )}
            {t('cms.retail.link') === 'cms.retail.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.retail.link')}`}>
                  {t('cms.retail.label')}
                </Link>
              </li>
            )}
            {t('cms.affiliateprogram.link') === 'cms.affiliateprogram.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.affiliateprogram.link')}`}>
                  {t('cms.affiliateprogram.label')}
                </Link>
              </li>
            )}
            {t('cms.tvbroadcastdates.link') === 'cms.tvbroadcastdates.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.tvbroadcastdates.link')}`}>
                  {t('cms.tvbroadcastdates.label')}
                </Link>
              </li>
            )}
            {t('cms.press.link') === 'cms.press.link' ? null : (
              <li className={styles.FooterLinksListItem}>
                <Link onClick={clickFooterLink} data-category="About" to={`/${t('cms.press.link')}`}>
                  {t('cms.press.label')}
                </Link>
              </li>
            )}
          </ul>
        </div>
      )}
    </I18n>
  );

  contents.push(
    <I18n>
      {t => (
        <CustomerQuery>
          {({ data: { customer } }) => (
            <div className={styles.FooterGroupContent}>
              <ul className={styles.FooterLinksList}>
                {t('cms.phoneProductAdvisor.link') === 'cms.phoneProductAdvisor.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link
                      onClick={clickFooterLink}
                      data-category="Customer Service"
                      to={`/${t('cms.phoneProductAdvisor.link')}`}
                    >
                      {t('cms.phoneProductAdvisor.label')}
                    </Link>
                  </li>
                )}
                {!hotlineActive || t('cms.hotline.link') === 'cms.hotline.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link onClick={clickFooterLink} data-category="Customer Service" to={`/${t('cms.hotline.link')}`}>
                      {t('cms.hotline.label')}
                    </Link>
                  </li>
                )}
                {t('cms.contact.link') === 'cms.contact.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link onClick={clickFooterLink} data-category="Customer Service" to={`/${t('cms.contact.link')}`}>
                      {t('cms.contact.label')}
                    </Link>
                  </li>
                )}
                {t('cms.helpfaq.link') === 'cms.helpfaq.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link onClick={clickFooterLink} data-category="Customer Service" to={`/${t('cms.helpfaq.link')}`}>
                      {t('cms.helpfaq.label')}
                    </Link>
                  </li>
                )}
                {storeConfiguration?.asam_tellja?.general?.is_active !== '1' ||
                t('cms.recruit.link') === 'cms.recruit.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link onClick={clickFooterLink} data-category="Customer Service" to={`/${t('cms.recruit.link')}`}>
                      {t('cms.recruit.label')}
                    </Link>
                  </li>
                )}
                {t('cms.gift.link') === 'cms.gift.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link onClick={clickFooterLink} data-category="Customer Service" to={`/${t('cms.gift.link')}`}>
                      {t('cms.gift.label')}
                    </Link>
                  </li>
                )}
                {storeConfiguration?.newsletter?.general?.active !== '1' ||
                t('cms.newsletter.link') === 'cms.newsletter.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link
                      onClick={clickFooterLink}
                      data-category="Customer Service"
                      to={`/${t('cms.newsletter.link')}`}
                    >
                      {t('cms.newsletter.label')}
                    </Link>
                  </li>
                )}
                {t('cms.account.label') === 'cms.account.label' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link
                      onClick={clickFooterLink}
                      data-category="Customer Service"
                      to={customer ? '/account' : '/sign-in'}
                    >
                      {t('cms.account.label')}
                    </Link>
                  </li>
                )}
                {t('cms.order.link') === 'cms.order.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link onClick={clickFooterLink} data-category="Customer Service" to={`/${t('cms.order.link')}`}>
                      {t('cms.order.label')}
                    </Link>
                  </li>
                )}
                {t('cms.payment.link') === 'cms.payment.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link onClick={clickFooterLink} data-category="Customer Service" to={`/${t('cms.payment.link')}`}>
                      {t('cms.payment.label')}
                    </Link>
                  </li>
                )}
                {t('cms.deliveryshipping.link') === 'cms.deliveryshipping.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link
                      onClick={clickFooterLink}
                      data-category="Customer Service"
                      to={`/${t('cms.deliveryshipping.link')}`}
                    >
                      {t('cms.deliveryshipping.label')}
                    </Link>
                  </li>
                )}
                {t('cms.cancelshipping.link') === 'cms.cancelshipping.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link
                      onClick={clickFooterLink}
                      data-category="Customer Service"
                      to={`/${t('cms.cancelshipping.link')}`}
                    >
                      {t('cms.cancelshipping.label')}
                    </Link>
                  </li>
                )}
                {t('cms.returnshipping.link') === 'cms.returnshipping.link' ? null : (
                  <li className={styles.FooterLinksListItem}>
                    <Link
                      onClick={clickFooterLink}
                      data-category="Customer Service"
                      to={`/${t('cms.returnshipping.link')}`}
                    >
                      {t('cms.returnshipping.label')}
                    </Link>
                  </li>
                )}
              </ul>
            </div>
          )}
        </CustomerQuery>
      )}
    </I18n>
  );

  contents.push(!isDesktop ? languageSwitcherLinks : null);

  contents.push(
    <I18n>
      {t => (
        <div className={styles.FooterGroupContent}>
          <div className={styles.CarriersHolder}>
            <FooterCarriers carriers={carriers} />
          </div>
          <div className="HideMobile HideTablet">
            <div className={[styles.FooterGroupTitle, styles.FooterGroupTitleSecondary].join(' ')}>
              {t('languageSwitcher.title')}
            </div>
            {languageSwitcherLinks}
          </div>
        </div>
      )}
    </I18n>
  );

  contents.push(
    <I18n>
      {t => (
        <div className={styles.FooterGroupContent}>
          <div className={styles.PaymentIcon}>
            <FooterPayment cardIcons={cardIcons} paymentsObj={storeConfiguration.payment} storeCode={storeCode} />
          </div>

          <div className="HideMobile HideTablet">
            <div className={[styles.FooterGroupTitle, styles.FooterGroupTitleSecondary].join(' ')}>
              {t('footer.safeShopping')}
            </div>
            {trustedShopActive ? (
              <a
                className={styles.FooterTrust}
                href={trustedLink}
                target="_blank"
                rel="noopener noreferrer nofollow"
                onClick={() => OutboundLink({ storeConfiguration, url: trustedLink })}
              >
                <img src={`${footerSprite}#trustedshops`} alt="TrustedShops" width="35" height="35" />
              </a>
            ) : null}
            {paybackActive ? (
              <Link to={`/${t('cms.payback.link')}`}>
                <img
                  className={styles.FooterPaybackLogo}
                  src={`${footerSprite}#paybackLogo`}
                  alt="Payback Logo"
                  width={89}
                  height={35}
                />
              </Link>
            ) : null}
          </div>
        </div>
      )}
    </I18n>
  );

  if (trustedShopActive || paybackActive) {
    contents.push(
      <I18n>
        {t => (
          <div className="ShowMobile ShowTablet">
            {trustedShopActive ? (
              <a className={styles.FooterTrust} href={trustedLink} target="_blank" rel="noopener noreferrer nofollow">
                <img src={`${footerSprite}#trustedshops`} alt="TrustedShops" width="35" height="35" />
              </a>
            ) : null}

            {paybackActive ? (
              <Link to={`/${t('cms.payback.link')}`}>
                <img
                  className={styles.FooterPaybackLogo}
                  src={`${footerSprite}#paybackLogoMobile`}
                  alt="Payback Logo"
                  width={35}
                  height={35}
                />
              </Link>
            ) : null}
          </div>
        )}
      </I18n>
    );
  }

  return (
    <I18n>
      {t => (
        <div className={styles.FooterContent}>
          <ChatChampAddProduct />
          <div className={[styles.Footer, secondary ? styles.Secondary : null].join(' ')}>
            {!secondary ? (
              <Container>
                <div className={styles.FooterLinks}>
                  <Multiselect headers={headers} contents={contents} onlyMobile groupClass={styles.FooterColumn} />
                </div>
                <div className={styles.FooterAdditional}>
                  <FooterPrivacyLinks storeCode={storeCode} storeConfiguration={storeConfiguration} t={t} />
                  {storeCode === 'de-DE' ? <FooterApps storeConfiguration={storeConfiguration} /> : null}
                  <div className={styles.FooterSmallLogo}>
                    <img src={`${footerSprite}#small_logo`} alt="asambeauty" width="48" height="43" />
                  </div>
                  <FooterSocials storeCode={storeCode} storeConfiguration={storeConfiguration} t={t} />
                  <div className={styles.Copyright}>
                    &copy; {new Date().getFullYear()} {t('footer.companyName')} {t('footer.copyright')}.
                  </div>
                  <FooterPrivacyText storeConfiguration={storeConfiguration} t={t} />
                </div>
              </Container>
            ) : (
              <FooterSecondaryVariant storeConfiguration={storeConfiguration} t={t} />
            )}
          </div>
          <CustomerWithAddressesQuery fetchPolicy="network-only">
            {({ data: { customer } }) => {
              return (
                <React.Fragment>
                  <GoogleTagManager4Customer customer={customer} />
                  <PageMetaTracking customer={customer} storeConfiguration={storeConfiguration} />
                </React.Fragment>
              );
            }}
          </CustomerWithAddressesQuery>
          {process.env.NODE_ENV !== 'production' ? <WebVitals /> : null}
        </div>
      )}
    </I18n>
  );
});

export default Footer;
