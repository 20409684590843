import { HashSha256, HashSha512 } from '../../../../helpers/Hash/Hash';
import { GoogleTagManager4Pageview } from './Pageview';

export const GoogleTagManager4Customer = ({ customer }) => {
  if (typeof window !== 'undefined') {
    if (window.dataLayer) {
      const customerDataFilled = window.dataLayer.filter(dataLayerEntry => dataLayerEntry.customer?.customerId > 0);

      let dataLayerData;
      if (customer) {
        dataLayerData = {
          event: 'Customer Data',
          eventType: 'customer_data',
          key: `customer_data_${customer.id}`,
          customer: {
            customerLoggedIn: 1,
            customerId: customer.id,
            hashedCustomerId: HashSha512(customer.id),
            customerFirstname: customer.firstname,
            customerLastname: customer.lastname,
            customerFullname: `${customer.firstname} ${customer.lastname}`,
            hashedCustomerFullname: HashSha512(`${customer.firstname} ${customer.lastname}`),
            customerEmail: customer.email,
            customerPrefix: customer.prefix,
            hashedCustomerEmail: HashSha512(customer.email),
            hashedCustomerEmail256: HashSha256(customer.email)
          }
        };

        if (customer.addresses) {
          const customerBillingAddress = customer.addresses.find(item => item.defaultBilling === true);
          if (customerBillingAddress) {
            dataLayerData.customer.customerCity = customerBillingAddress.city;
            dataLayerData.customer.hashedCustomerCity = HashSha512(customerBillingAddress.city);
            dataLayerData.customer.customerPostcode = customerBillingAddress.postcode;
            dataLayerData.customer.hashedCustomerPostcode = HashSha512(customerBillingAddress.postcode);
            dataLayerData.customer.customerAddress = `${customerBillingAddress.street} ${customerBillingAddress.city}`;
            dataLayerData.customer.hashedCustomerAddress = HashSha512(dataLayerData.customer.customerAddress);
            dataLayerData.key = `customer_data_with_address_${customer.id}`;
          }
        }
      } else if (customerDataFilled.length > 0) {
        return null;
      } else {
        dataLayerData = {
          event: 'Customer Data',
          eventType: 'customer_data',
          key: 'no_customer_data_event',
          customer: {
            customerLoggedIn: 0,
            customerId: 0,
            customerEmail: ''
          }
        };
      }

      return GoogleTagManager4Pageview({ dataLayerData });
    }
  }
  return null;
};
