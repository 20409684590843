import React from 'react';
import { SocialClickTracking } from '../../elements/Tracking/GoogleTagManager/GA4/SocialClick';
import styles from './Footer.module.scss';

export const FooterSocials = ({ storeCode, storeConfiguration, t }) => {
  return (
    <div className={styles.SocialSection}>
      <div className={styles.SocialSectionTitle}>{t('footer.social')}</div>
      <ul className={styles.SocialIconsList}>
        {storeCode !== 'fr-FR' ? (
          <>
            <li className={[styles.SocialIconsListItem, styles.Youtube].join(' ')}>
              <a
                href="https://www.youtube.com/channel/UCJjSZi5luGxZ-lZbeIGvigA"
                target="_blank"
                rel="noopener noreferrer nofollow"
                aria-label="Youtube"
                title="asambeauty on Youtube"
                onClick={() =>
                  SocialClickTracking({
                    storeConfiguration,
                    network: 'youtube',
                    url: 'https://www.youtube.com/channel/UCJjSZi5luGxZ-lZbeIGvigA'
                  })
                }
              >
                {'asambeauty on Youtube'}
              </a>
            </li>
            <li className={[styles.SocialIconsListItem, styles.Facebook].join(' ')}>
              <a
                href="https://www.facebook.com/asambeauty/?hl=de"
                target="_blank"
                rel="noopener noreferrer nofollow"
                aria-label="Facebook"
                title="asambeauty on Facebook"
                onClick={() =>
                  SocialClickTracking({
                    storeConfiguration,
                    network: 'facebook',
                    url: 'https://www.facebook.com/asambeauty/?hl=de'
                  })
                }
              >
                {'asambeauty on Facebook'}
              </a>
            </li>
            <li className={[styles.SocialIconsListItem, styles.Instagram].join(' ')}>
              <a
                href="https://www.instagram.com/asambeauty/?hl=de"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                title="asambeauty on Instagram"
                onClick={() =>
                  SocialClickTracking({
                    storeConfiguration,
                    network: 'instagram',
                    url: 'https://www.instagram.com/asambeauty/?hl=de'
                  })
                }
              >
                {'asambeauty on Instagram'}
              </a>
            </li>
            <li className={[styles.SocialIconsListItem, styles.Pinterest].join(' ')}>
              <a
                href="https://www.pinterest.de/asambeauty/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                aria-label="Pinterest"
                title="asambeauty on Pinterest"
                onClick={() =>
                  SocialClickTracking({
                    storeConfiguration,
                    network: 'pinterest',
                    url: 'https://www.pinterest.de/asambeauty/'
                  })
                }
              >
                {'asambeauty on Pinterest'}
              </a>
            </li>
          </>
        ) : (
          <>
            <li className={[styles.SocialIconsListItem, styles.Facebook].join(' ')}>
              <a
                href="https://www.facebook.com/asambeauty.fr/"
                target="_blank"
                rel="noopener noreferrer nofollow"
                aria-label="Facebook"
                title="asambeauty on Facebook"
                onClick={() =>
                  SocialClickTracking({
                    storeConfiguration,
                    network: 'facebook',
                    url: 'https://www.facebook.com/asambeauty.fr/'
                  })
                }
              >
                {'asambeauty on Facebook'}
              </a>
            </li>
            <li className={[styles.SocialIconsListItem, styles.Instagram].join(' ')}>
              <a
                href="https://www.instagram.com/asambeauty_fr"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                title="asambeauty on Instagram"
                onClick={() =>
                  SocialClickTracking({
                    storeConfiguration,
                    network: 'instagram',
                    url: 'https://www.instagram.com/asambeauty_fr'
                  })
                }
              >
                {'asambeauty on Instagram'}
              </a>
            </li>
          </>
        )}
      </ul>
    </div>
  );
};
