import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import Countdown, { zeroPad } from 'react-countdown';
import Cookies from 'js-cookie';
import { CmsHeadline } from '../Cms/Modules/Components/CmsHeadline';
import { CmsImage } from '../Cms/Modules/Components/CmsImages';
import { CmsVideo } from '../Cms/Modules/Components/CmsVideo';
import { CmsRichtext } from '../Cms/Modules/Components/CmsRichText';
import { Button } from '../../ui/Button/Button';
import ScrollEvent from '../../helpers/Viewport/ScrollEvent';
import generalSVG from '../../../../../assets/images/sprites/generalSprite.svg';
import styles from './BambuserPlayer.module.scss';

const BambuserPlayer = withRouter(({ history, widgetSource, itemContent, context }) => {
  const [badgeStatus, setBadgeStatus] = useState(true);
  let showId = (itemContent && itemContent.widgetSource) || widgetSource;
  const initPlayer = () => {
    window.initBambuserLiveShopping = item => {
      window.initBambuserLiveShopping.queue.push(item);
    };
    window.initBambuserLiveShopping.queue = [];
    const scriptNode = document.createElement('script');
    scriptNode.src = 'https://lcx-embed.bambuser.com/asambeauty/embed.js';
    scriptNode.id = 'bambuserScript';
    document.body.appendChild(scriptNode);
    const cookieConsent = Cookies.get('CookieConsent');

    window.initBambuserLiveShopping({
      showId,
      type: 'overlay'
    });

    window.onBambuserLiveShoppingReady = player => {
      player.configure({
        buttons: {
          dismiss: player.BUTTON.MINIMIZE
        },
        floatingPlayer: {
          navigationMode: player.FLOATING_PLAYER_NAVIGATION_MODE.MANUAL
        },
        enableFirstPartyCookies: !!cookieConsent?.includes('functional:true')
      });
      player.on(player.EVENT.NAVIGATE_BEHIND_TO, event => {
        if (window.location.href !== event.url) {
          const redirectURL = new URL(event.url);
          history.push(redirectURL.pathname);
        }
      });
    };
  };

  if (typeof window !== 'undefined') {
    if (window?.location?.search?.includes('?autoplayLiveShopping=')) {
      showId = window.location.search.split('?autoplayLiveShopping=').pop();
      initPlayer();
    }
  }

  // render badge based on status context itemContent and cookie
  if (context === 'badge' && badgeStatus && itemContent) {
    if (
      typeof window !== 'undefined' &&
      (document.cookie.includes('badgeClosed') ||
        (document.querySelectorAll('[data-bambuser-liveshopping-player-id]') &&
          document.querySelectorAll('[data-bambuser-liveshopping-player-id]')[0]))
    ) {
      return <div />;
    }
    // countdown renderer
    const timer = ({ days, hours, minutes, seconds, completed }) => {
      hours = days * 24 + hours;
      if (completed) {
        // render when countdown is completed
        return (
          <React.Fragment>
            <img src={`${generalSVG}#playButton`} className={styles.BadgePlay} alt="Play" />
            <div className={styles.BadgeTextWrapper}>
              <p className={styles.BadgeText}>{itemContent.live_text}</p>
            </div>
          </React.Fragment>
        );
      }
      // render a countdown
      return (
        <div className={styles.BadgeTextWrapper}>
          <span>{itemContent.preshow_text}</span>
          <span className={styles.BadgeText}>
            {zeroPad(hours)}:{zeroPad(minutes)}:{zeroPad(seconds)}
          </span>
        </div>
      );
    };

    return (
      <ScrollEvent>
        {({ scrolling }) => (
          <div className={[styles.Badge, scrolling && styles.BadgeAnimate].join(' ')}>
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <span
                    onClick={() => [setBadgeStatus(false), (document.cookie = 'badgeClosed')]} // eslint-disable-line
              className={[styles.BadgeClose, 'icon-x'].join(' ')}
            />
            {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
            <div
              id={showId}
              className={styles.BadgeBackground}
              style={{
                backgroundImage: `url(${itemContent.image &&
                  itemContent.image.fields &&
                  itemContent.image.fields.file &&
                  itemContent.image.fields.file.url})`
              }}
              onClick={() => {
                initPlayer();
                setBadgeStatus(false);
              }}
            >
              <span className={styles.Live} />

              <span className={styles.LivePulse} />
              {(itemContent.replace_countdown_text || itemContent.preshow_countdown) && (
                <React.Fragment>
                  {itemContent.replace_countdown_text ? (
                    <div className={styles.BadgeTextWrapper}>
                      <span>{itemContent.preshow_text}</span>
                      <p>{itemContent.replace_countdown_text}</p>
                    </div>
                  ) : (
                    <Countdown date={itemContent.preshow_countdown} renderer={timer} daysInHours />
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        )}
      </ScrollEvent>
    );
  }

  if (itemContent && context === 'widget') {
    return (
      <div id={showId}>
        {itemContent.fields.headline && itemContent.fields.headline_position === true ? (
          <CmsHeadline headline={itemContent.fields.headline} headlineTag={itemContent.fields.headline_type} />
        ) : null}
        {itemContent.fields.image ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div className="Interactive" onClick={() => initPlayer()}>
            <CmsImage
              largeImage={
                itemContent.fields.image && itemContent.fields.image[0]
                  ? itemContent.fields.image[0].fields.file.url
                  : null
              }
              smallImage={itemContent.fields.image[1] ? itemContent.fields.image[1].fields.file.url : null}
              imageAlt={
                itemContent.fields.image[0] && itemContent.fields.image[0] ? itemContent.fields.image[0].fields : null
              }
              imgCopyright={itemContent.fields.image_copyright}
              source="BambuserPlayer.js"
            />
          </div>
        ) : null}

        {!itemContent.fields.image && itemContent.fields.video ? (
          <CmsVideo videoId={itemContent.fields.video} videoCopyright={itemContent.fields.image_copyright} />
        ) : null}

        {itemContent.fields.headline && itemContent.fields.headline_position !== true ? (
          <CmsHeadline headline={itemContent.fields.headline} headlineTag={itemContent.fields.headline_type} />
        ) : null}
        {itemContent.fields.text ? (
          <CmsRichtext
            text={itemContent.fields.text}
            smallPrint={itemContent.fields.small_print}
            textFormat={itemContent.fields.text_align}
          />
        ) : null}
        {itemContent.fields.cta && (
          <Button size="small" onClick={() => initPlayer()}>
            {itemContent.fields.cta}
          </Button>
        )}
      </div>
    );
  }
  return null;
});

export default BambuserPlayer;
