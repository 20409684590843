import React, { useEffect, useState } from 'react';
import { InnerHTML } from '@deity/falcon-ui-kit';
import withStoreConfiguration from '../../../helpers/StoreConfiguration/StoreConfiguration';
import { CheckDays } from '../../../helpers/DeliveryTime/CheckDays';

const TrustedShopBadge = withStoreConfiguration(({ storeConfiguration, lastOrder }) => {
  if (lastOrder && parseInt(storeConfiguration?.asam_layoutconfiguration?.trustedshop?.active, 10)) {
    // TrustedShop ID
    const tsIdConfig = storeConfiguration?.asam_layoutconfiguration?.trustedshop?.id
      ? storeConfiguration.asam_layoutconfiguration.trustedshop.id
      : '';
    const [date, setDate] = useState(new Date());
    useEffect(() => {
      const updateDeliveryTime = () => setDate(CheckDays(storeConfiguration));
      updateDeliveryTime();
      const customCheckout = document.getElementById('customCheckout');
      if (customCheckout) {
        const ts = document.createElement('script');
        // eslint-disable-next-line no-underscore-dangle
        let tsid = tsIdConfig;
        // if there's nothing in the trustedshop id field
        if (tsIdConfig === '') {
          tsid = 'XD44DBE0B47337B705AEE09C7FD01632A';
        }
        ts.type = 'text/javascript';
        ts.charset = 'utf-8';
        ts.async = true;
        ts.id = 'badgeScript';
        ts.src = `https://widgets.trustedshops.com/js/${tsid}.js`;
        const tsScript = document.getElementsByTagName('script')[0];
        const tsBadge = document.getElementById('badgeScript');
        if (!tsBadge) {
          tsScript.parentNode.insertBefore(ts, tsScript);
        }
      }
    }, []);
    const html = `
      <div id="trustedShopsCheckout">
      <span id="tsCheckoutOrderNr">${lastOrder.referenceNo}</span>
      <span id="tsCheckoutBuyerEmail">${lastOrder.customerEmail}</span>
      <span id="tsCheckoutOrderAmount">${lastOrder.grandTotal}</span>
      <span id="tsCheckoutOrderCurrency">${lastOrder.orderCurrencyCode}</span>
      <span id="tsCheckoutOrderPaymentType">${lastOrder.paymentMethodName}</span>
      <span id="tsCheckoutOrderEstDeliveryDate">${date.toISOString().split('T')[0]}</span>
      </div>
      <div id="customCheckout"></div>
      <style type="text/css">
       div#trustedShopsCheckout {
        display: none;
       }
       div#customCheckout {
          padding-bottom: 32px
       }
       div#customCheckout > div > div {
          width: 100% !important;
       }
       @media (min-width: 1024px) {
        div#customCheckout{
          padding-top: 32px;
        }
       }
       @media (max-width: 1024px) {
        div#customCheckout{
          margin: 0 20px
        }
       }
      </style>
      <script type="text/javascript">
      (function () {
        _tsConfig = {
          'yOffset': '60px',
          'variant': 'reviews',
          'customElementId': '',
          'trustcardDirection': '',
          'customBadgeWidth': '',
          'customBadgeHeight': '',
          'disableResponsive': 'false',
          'disableTrustbadge': 'true',
          'trustCardTrigger': 'mouseenter',
          'customCheckoutElementId': 'customCheckout'
        }
      })();
      </script>
    `;
    return <InnerHTML html={html} allowScript allowStyle />;
  }
  return null;
});

export default TrustedShopBadge;
