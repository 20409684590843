import React, { useEffect, useState } from 'react';
import { Toggle } from 'react-powerplug';
import { AddedToWishlistPopup } from '../AddedToWishlistPopup/AddedToWishlistPopup';

const WishlistPopup = () => {
  const [popup, setPopup] = useState(true);

  useEffect(() => {
    setTimeout(() => setPopup(false), 4000);
  }, []);

  return popup ? (
    <Toggle initial>{({ on, toggle }) => <AddedToWishlistPopup on={on} toggle={toggle} />}</Toggle>
  ) : (
    <div />
  );
};

export default WishlistPopup;
