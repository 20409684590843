import { Component } from 'react';
import { debounce } from 'lodash';

class ScrollEvent extends Component {
  isMounted = false;

  constructor(props) {
    super(props);
    const scroll = {
      scrolling: false
    };
    this.state = {
      scroll
    };

    this.handleScrollUpdate = debounce(this.handleScrollUpdate.bind(this), 100);
  }

  componentDidMount() {
    this.isMounted = true;
    this.handleScrollUpdate();
    window.addEventListener('scroll', this.handleScrollUpdate);
  }

  componentWillUnmount() {
    this.isMounted = false;
    window.removeEventListener('scroll', this.handleScrollUpdate);
  }

  handleScrollUpdate() {
    const scroll = {
      scrolling: window && window.pageYOffset > 20
    };
    if (this.isMounted) {
      this.setState({
        scroll
      });
    }
  }

  render() {
    const { scroll } = this.state;
    return this.props.children(scroll);
  }
}

export default ScrollEvent;
